.pick-time-range {
    padding: 15px 30px;
}

div.time-field {
    text-align: left;
    padding: 0.5em;
}

.time-label {
    /*margin: auto;*/
    width: 150px;
    display: inline-block;
}

.time-field input[type="time"] {
    font-family: 'Kdam Thmor Pro', sans-serif;
    width: 100px;
}

