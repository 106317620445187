* {
    font-family: 'Merriweather', sans-serif;
    line-height: 2;
}

tr {
    height: 50px;
}

.table-header {
    width: 35%;
}
