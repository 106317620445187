:root {
    --primary-color-main: rgb(223, 120, 97);
    --primary-color-light: rgb(236, 120, 97, 0.6);
    --secondary-color-main: #75b38b;
}

table.availability-table {
    margin: auto;
}

.availability-table-cell {
    min-width: 48px;
    min-height: 24px;
}

table.availability-table {
    border-collapse: collapse;
    padding: 0;
    user-select: none;
}

table.availability-table tbody td {
    border: 1px solid #999;
    text-align: center;
}

table.availability-table .hour {
    font-family: 'Kdam Thmor Pro', sans-serif;
    font-size: small;
    font-style: normal;
}

table.availability-table .date {
    font-family: 'Kdam Thmor Pro', sans-serif;
    font-size: small;
    font-style: normal;
    vertical-align: bottom;
    text-align: center;
}