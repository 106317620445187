.link-input {
    display: block;
    width: 100%;
    border-radius: 10px;
    font-size: 14px;
    padding: 5px 10px;
    border: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    transition: .3s box-shadow;
}

.link-input:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
}