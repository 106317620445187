.event-creation * {
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

div {
    text-align: left;
}

#time-range-picker-and-btn {
    /* Center the form on the page */
    min-width: 25em;
    max-width: 36em;
}

div#confirm {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}


/* input field alignment */
div.input {
    display: flex;
    align-items: center;
}

div.input.item {
    flex-grow: 1;
}

div.input.item.padding {
    flex-grow: 2;
}


.MuiInput-root input {
    text-align: center;
}

/* datepicker alignment */
div.inner-flex {
    display: flex;
    align-items: center;
}

div.inner-flex .item {
    flex-grow: 2;
}

div.inner-flex .date-selection {
    flex-grow: 1;
}

/* Time Range Alignment */
div.inner-flex .picker-and-btn {
    flex-grow: 4;
    min-width: 24em;
}

